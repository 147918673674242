define("discourse/plugins/discourse-arashi-color-picker/discourse/initializers/color-picker", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const colors = ["FF0000", "00FF00", "0000FF", "000000", "FFFFFF", "1ABC9C", "2ECC71", "3498DB", "9B59B6", "34495E", "16A085", "27AE60", "2980B9", "8E44AD", "2C3E50", "F1C40F", "E67E22", "E74C3C", "ECF0F1", "95A5A6", "F39C12", "D35400", "C0392B", "BDC3C7", "7F8C8D"];
  const $color_panel = $(`
    <div id="arashi_color_panel">
      ${colors.map(color => `
          <div class="color_grid" data-color="${color}"></div>
       `).join("")}
    </div>
  `);
  $color_panel.find(".color_grid").each((i, e) => {
    const $e = $(e);
    $e.css("background-color", '#' + $e.data("color"));
  });
  function isColorPanelVisible() {
    return $color_panel.hasClass("show");
  }
  function resizeCallback() {
    if (!isColorPanelVisible()) {
      return;
    }
    const self = document.getElementsByClassName("arashi_plugin_color_picker_button")[0];
    const container = document.getElementById('reply-control');
    const selfBox = self.getBoundingClientRect();
    const containerBox = container.getBoundingClientRect();
    const left = selfBox.left - containerBox.left;
    const top = selfBox.top - containerBox.top + selfBox.height;
    $color_panel.css("left", left).css("top", top);
  }
  function toggleColorPanel() {
    $color_panel.toggleClass("show");
    let visible = isColorPanelVisible();
    if (!visible) {
      $color_panel.off('click');
    }
    return visible;
  }
  function applyColorCallback(text_area, e) {
    const color_grid = e.target;
    const color = '#' + color_grid.getAttribute("data-color");
    if (!color) {
      return;
    }
    const selectionStart = text_area.selectionStart;
    const selectionEnd = text_area.selectionEnd;
    if (selectionStart === selectionEnd) {
      return;
    }
    const selection = text_area.value.substring(selectionStart, selectionEnd);
    const regex = /\[color=([^\]]+)](.+?)\[\/color\]/;
    let replacementText = '';
    let result = regex.exec(selection);
    if (result) {
      const appliedColor = result[1];
      if (appliedColor === color) {
        replacementText = result[2];
      } else {
        replacementText = `[color=${color}]${result[2]}[/color]`;
      }
    } else {
      replacementText = `[color=${color}]${selection}[/color]`;
    }
    text_area.setRangeText(replacementText, selectionStart, selectionEnd, 'select');
    text_area.focus();
    toggleColorPanel();
  }
  function initializePlugin(api) {
    $color_panel.css("border-color", $(".grippie").css("background-color"));
    api.onToolbarCreate(toolbar => {
      const reply_control = $("#reply-control");
      if (!reply_control.find('#arashi_color_panel').length) {
        reply_control.append($color_panel);
        const observer = new MutationObserver(resizeCallback);
        observer.observe(reply_control.get(0), {
          attributes: true
        });
      }
      toolbar.addButton({
        id: "arashi_plugin_color_picker_button",
        group: "extras",
        icon: "palette",
        action: function () {
          const text_area = reply_control.find("textarea").get(0);
          if (!toggleColorPanel()) {
            return;
          }
          text_area.focus();
          resizeCallback();
          $color_panel.on('click', applyColorCallback.bind(this, text_area));
        }
      });
    });
  }
  var _default = _exports.default = {
    name: "arashi-color-pcker",
    initialize: function () {
      (0, _pluginApi.withPluginApi)("0.1", api => initializePlugin(api));
    }
  };
});